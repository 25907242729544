import { render, staticRenderFns } from "./security_patch_upgrade_alert_modal.vue?vue&type=template&id=8d066290"
import script from "./security_patch_upgrade_alert_modal.vue?vue&type=script&lang=js"
export * from "./security_patch_upgrade_alert_modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports